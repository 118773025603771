

.logoWhite {
	width: 6rem;
	height: 6rem;
	cursor: pointer;
	background: url('../images/logo2.png') no-repeat 100% 50%;
	background-size: 100%;
}

.logoColor {
	width: 6rem;
	height: 2.5rem;
	cursor: pointer;
	background-color: #ffff;
	background: url('../images/logo.png') no-repeat 100% 50%;
	background-size: 100%;
	border-radius: 2.25rem;
}

.minimalLogo {
	width: 8rem;
	height: 8rem;
	cursor: pointer;
	background: url('../images/logo2.png') no-repeat 100% 50%;
	background-size: 100%;
	border-radius: 2.25rem;
}